import { defineComponent } from 'vue';
export default defineComponent({
  name: 'DefaultError',
  props: {
    placeholderImage: {
      type: [Object, String],
      default: ''
    },
    statusImage: {
      type: [Object, String],
      default: ''
    },
    message: {
      type: String,
      default: '出错了'
    }
  }
});