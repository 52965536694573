import { defineComponent } from 'vue';
import DefaultError from './components/DefaultError.vue';
export default defineComponent({
  name: '404',
  components: {
    DefaultError: DefaultError
  },
  computed: {
    placeholder: function placeholder() {
      return require('./assets/img/404_icon.png');
    },
    status: function status() {
      return require('./assets/img/404.png');
    }
  }
});