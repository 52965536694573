import { defineComponent } from 'vue';
import DefaultError from './components/DefaultError.vue';
export default defineComponent({
  name: '403',
  components: {
    DefaultError: DefaultError
  },
  computed: {
    placeholder: function placeholder() {
      return require('./assets/img/403_icon.png');
    },
    status: function status() {
      return require('./assets/img/403.png');
    }
  }
});